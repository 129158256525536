import _ from 'lodash'
import moment from 'moment'
import axios from '../common/axios.js'

export default {
	data() {
		return {
			
		}
	},
	created(){
		
	},
	methods: {
		get_date(item){
			if (item) {
				moment.locale(this.$store.state.ui.langue.substr(0, 2));
				return moment(String(item.modified)).format('D MMMM YYYY H:mm')
			}
			return '';
		},
		get_surface(item){
			let area = item.area;

			let result = this.$t('map_tools_surface_label')+' : ';
			result += area >= (1000 * 1000) ? _.round(area / (1000*1000),2)+' km²' : _.round(area,2)+' m²'

			if (this.$store.getters.hasCustomSurface) {
				result += '<br/>'+this.$store.state.ui.sourcing.map_tools_surface_custom_label+' : ';
				let custom_area = area * this.$store.state.ui.sourcing.map_tools_surface_custom_ratio
				result += custom_area >= (1000 * 1000) ? _.round(custom_area / (1000*1000),2)+' km²' : _.round(custom_area,2)+' m²'
			}

			return result
		},
		setSurface(item){
			this.$store.dispatch('set_surface', item)
		},
		deleteSurface(item){
			let self = this;
			this.$dialog.confirm(this.$t('filter_fav_confirm'), {loader: true})
			.then(dialog => {
				//AS--> Demande la suppression du favoris en DB
				axios.get('/plugin_projet/plugin_projet_iannuaire/delete_surface/'+item.id)
				.then(function (response) {

					//AS--> Ferme l'ancienne boîte de dialogue
					dialog.close();

					//AS--> En cas de succès, supprime la valeur en FO et ferme la modale
					if(response.data == 'OK'){
						self.$store.dispatch('updateUser');
						
					//AS--> Sinon on prévient de l'impossibilité de supprimer le favoris
					}else{
						self.$dialog.alert(this.$t('filter_fav_echec'));
					}
				}).catch(function (error) {})

			});
		}
	},
	computed:{
	},
	components: {
		SousMenu: () => import('./sous_menu.vue')
	}
}